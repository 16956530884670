import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function TestimonialTwo() {
  var settings = {
    dots: false,
    arrow: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
  };
  return (
    <ul>
      <Slider {...settings}>


        <li className="item">
          <div className="texts">
            <p>
            When putting together a content team for programming on all platforms you want Nikki Bates 
            on that team. I worked with Nikki for nearly ten years and can attest to her integrity, 
            leadership and exceptional ability to work with others across all departments 
            of a media company. She has the experience and passion to take on any project. 
            </p>
            <div className="author" style={{width: 500}}>
              <div className="image">
                <div
                  className="main"
                  style={{
                    backgroundImage: `url(${
                      process.env.PUBLIC_URL + "img/about/2.jpg"
                    })`,
                  }}
                ></div>
              </div>
              <div className="short">
                <h3 className="author" style={{flexWrap: 'wrap', style: 150}}>
                  <span>Jason Effinger</span>
                </h3>
                <h3 className="job">
                  <div style={{wordWrap: 'break-word', width: 250, lineHeight: 1.5}}>
                    Fmr. Chief Digital and Technology Officer,  Gray Television
                  </div>
                </h3>
              </div>
            </div>
          </div>
        </li>
        {/* End single slide item */}

        <li className="item">
          <div className="texts">
            <p>
            There is no better content creator, project manager or team leader than Nikki Bates. 
            She has the creativity, innovative thinking and leadership skills to deliver results 
            for every project and will exceed expectations and goals every time. She is an asset 
            to every team and every product and project will succeed due to her extraordinary contributions. 
            </p>
            <div className="author" style={{width: 500}}>
              <div className="image" >
                <div
                  className="main"
                  style={{
                    backgroundSize: 'cover',
                    backgroundImage: `url(${
                      process.env.PUBLIC_URL + "img/about/3.jpg"
                    })`,
                  }}
                ></div>
              </div>
              <div className="short">
              <h3 className="author" style={{flexWrap: 'wrap', style: 150}}>
                  <span>Julia Campbell</span>
                </h3>
                <h3 className="job">
                  <div style={{wordWrap: 'break-word', width: 250, lineHeight: 1.5}}>
                    General Manager at The Meta Branded Content Project
                  </div>
                </h3>
              </div>
            </div>
          </div>
        </li>

        <li className="item">
          <div className="texts">
            <p>
            I have worked with Nikki in a variety of roles, at multiple companies and in community service projects. 
            She’s confident, organized, willing to learn and does everything she does at a high level.
            </p>
            <div className="author" style={{width: 500}}>
              <div className="image">
                <div
                  className="main"
                  style={{
                    backgroundImage: `url(${
                      process.env.PUBLIC_URL + "img/about/brandee.jpg"
                    })`,
                  }}
                ></div>
              </div>
              <div className="short">
              <h3 className="author" style={{flexWrap: 'wrap', style: 150}}>
                  <span>Brandee Weber</span>
                </h3>
                <h3 className="job">
                  <div style={{wordWrap: 'break-word', width: 250, lineHeight: 1.5}}>
                    Producer, Nebraska Public Media
                  </div>
                </h3>
              </div>
            </div>
          </div>
        </li>

        <li className="item">
          <div className="texts">
            <p>
            Nikki would make a great asset to any team. She is hard-working, has loads of experience 
            in all aspects of television production, and makes the work environment enjoyable with
            her can-do positive attitude.
            </p>
            <div className="author" style={{width: 500}}>
              <div className="image">
                <div
                  className="main"
                  style={{
                    backgroundImage: `url(${
                      process.env.PUBLIC_URL + "img/about/chad.jpg"
                    })`,
                  }}
                ></div>
              </div>
              <div className="short">
              <h3 className="author" style={{flexWrap: 'wrap', style: 150}}>
                  <span>Chad Barnhardt</span>
                </h3>
                <h3 className="job">
                  <div style={{wordWrap: 'break-word', width: 250, lineHeight: 1.5}}>
                  </div>
                </h3>
              </div>
            </div>
          </div>
        </li>

        <li className="item">
          <div className="texts">
            <p>
            Nikki’s passion for digital storytelling is not only evident in her work, but also in her work ethic. 
            She is driven, eager to learn, willing to take on both small and large tasks,.  Best of all, she is a 
            joy to work with! I have had the pleasure of working with Nikki in both a workplace environment and 
            in a community service environment. In both situations, she has proven she can play the role of leader 
            but is also comfortable playing a supporting role. I always jump at the chance to work with her!
            </p>
            <div className="author" style={{width: 500}}>
              <div className="image">
                <div
                  className="main"
                  style={{
                    backgroundImage: `url(${
                      process.env.PUBLIC_URL + "img/about/steph.jpg"
                    })`,
                  }}
                ></div>
              </div>
              <div className="short">
              <h3 className="author" style={{flexWrap: 'wrap', style: 150}}>
                  <span>Stephanie Reikofski</span>
                </h3>
                <h3 className="job">
                  <div style={{wordWrap: 'break-word', width: 250, lineHeight: 1.5}}>
                  Director of Digital Integration, Gray Television
                  </div>
                </h3>
              </div>
            </div>
          </div>
        </li>

        <li className="item">
          <div className="texts">
            <p>
            If I had the chance to work with Nikki Bates again, I’d do it in a heartbeat. 
            Innovative content creation, attention to detail, effective communication and 
            enthusiasm make her the seasoned media professional she is. She’s also really 
            fun to work with! I would recommend Nikki for a leading role without any reservation.
            </p>
            <div className="author" style={{width: 500}}>
              <div className="image">
                <div
                  className="main"
                  style={{
                    backgroundImage: `url(${
                      process.env.PUBLIC_URL + "img/about/liz.jpg"
                    })`,
                  }}
                ></div>
              </div>
              <div className="short">
              <h3 className="author" style={{flexWrap: 'wrap', style: 150}}>
                  <span>Liz Hayes</span>
                </h3>
                <h3 className="job">
                  <div style={{wordWrap: 'break-word', width: 250, lineHeight: 1.5}}>
                  Chief Strategy Officer, Meta Branded Content Project
                  </div>
                </h3>
              </div>
            </div>
          </div>
        </li>

        <li className="item">
          <div className="texts">
            <p>
            It was a pleasure to work and collaborate with Nikki for the project Connects: Parkinson's Nebraska. 
            She and the crew did an amazing job capturing the entity that is the Parkinson's community and
            highlighting the various projects Parkinson's Nebraska funds. I recommend working with Nikki and the 
            Nebraska Public Media team! 
            </p>
            <div className="author" style={{width: 500}}>
              <div className="image" >
                <div
                  className="main"
                  style={{
                    backgroundSize: 'cover',
                    backgroundImage: `url(${
                      process.env.PUBLIC_URL + "img/about/jess.jpg"
                    })`,
                  }}
                ></div>
              </div>
              <div className="short">
              <h3 className="author" style={{flexWrap: 'wrap', style: 150}}>
                  <span>Jessica Buss</span>
                </h3>
                <h3 className="job">
                  <div style={{wordWrap: 'break-word', width: 250, lineHeight: 1.5}}>
                  </div>
                </h3>
              </div>
            </div>
          </div>
        </li>

        <li className="item">
          <div className="texts">
            <p>
            Nikki is a thought provoker through analysis, discussion, and video storytelling. 
            She takes times to meticulously analyze the details of a project and is resolute in a 
            finished product that matches her initial goals. Her storytelling is sincere and heartfelt, 
            while avoiding sensationalism and staying true to facts. The consummate professional, 
            Nikki proudly represents her company through her voice and her actions.
            </p>
            <div className="author" style={{width: 500}}>
              <div className="image" >
                <div
                  className="main"
                  style={{
                    backgroundSize: 'cover',
                    backgroundImage: `url(${
                      process.env.PUBLIC_URL + "img/about/melissa.jpg"
                    })`,
                  }}
                ></div>
              </div>
              <div className="short">
              <h3 className="author" style={{flexWrap: 'wrap', style: 150}}>
                  <span>Melissa Dittmer</span>
                </h3>
                <h3 className="job">
                  <div style={{wordWrap: 'break-word', width: 250, lineHeight: 1.5}}>
                  </div>
                </h3>
              </div>
            </div>
          </div>
        </li>
        {/* End single slide item */}
      </Slider>
    </ul>
  );
}
