import React from "react";

const AboutTwo = () => {
  return (
    <>
      <div className="shane_tm_section" id="about">
        <div className="shane_tm_about">
          <div className="container">
            <div className="about_inner">
              <div className="left">
              <div
                  className="image"
                  data-aos="fade-up"
                  data-aos-duration="1200">
                <div className="video-container" style={{height: '512px' }}>
                  <video autoPlay muted loop>
                    <source src="https://nikki-bates.com/AboutMe.mp4" type="video/mp4" />
                  </video>
                </div>
              </div>

                {/* End image */}
              </div>
              {/* End left */}

              <div className="right">
                <div
                  className="shane_tm_title"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                >
                  <span>About Me</span>
                  <h3>Content Producer</h3>
                </div>
                <div
                  className="text"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                >
                  <p>
                  I'm a producer with 20+ years of media experience.  
                  From short form to long form productions, local to national audiences, 
                  commercial to public media, in front of and behind the camera - 
                  I have the knowledge and experience your team needs.  I enjoy telling engaging, 
                  impactful stories.
                  </p>
                </div>
                <div
                  className="shane_tm_button"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                >
                  <a href="img/resume/Nikki%20Bates%20resume.pdf" download>
                    Download CV
                  </a>
                </div>
              </div>
              {/* End right */}
            </div>
          </div>
          {/* End container */}
        </div>
      </div>
    </>
  );
};

export default AboutTwo;
