import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import ModalVideo from "react-modal-video";

const tabList = ["All", "Long Form", "Short Form", "On Air"];

const tabListContent = [
  {
    porftoliItems: [
      {
        img: "nebraska_roots",
        vid: true,
        channel: "custom",
        title: "Nebraska Roots:  Trees + Native Plants",
        meta: "",
        portfolioLink: "https://d34oa379y8jhb4.cloudfront.net/NETVOD/1080/50010820.mp4",
        delayAnimation: "0",
      },
      {
        img: "spring-game",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "Spring Game Coverage promo",
        meta: "",
        portfolioLink:"wQCBqDkGBLU",
        delayAnimation: "100",
      },
      {
        img: "parkinsons",
        vid: true,
        channel: "custom",
        title: "Connects:  Parkinson's Nebraska",
        meta: "",
        portfolioLink: "https://d34oa379y8jhb4.cloudfront.net/NETVOD/720/50018188.mp4",
        delayAnimation: "0",
      },
      {
        img: "water_machine",
        vid: true,
        channel: "custom",
        title: "Nebraska:  The Great American Water Machine",
        meta: "",
        portfolioLink: "https://d34oa379y8jhb4.cloudfront.net/NETVOD/720/50013668_A.mp4",
        delayAnimation: "100",
      },
      {
        img: "preclampsia",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "Preeclampsia story",
        meta: "",
        portfolioLink:"MH11OraSTNg",
        delayAnimation: "100",
      },
      {
        img: "focused",
        vid: true,
        channel: "custom",
        title: "Nebraska Focused:  Building Our Future",
        meta: "",
        portfolioLink:
          "https://d34oa379y8jhb4.cloudfront.net/NETVOD/720/50017302_B.mp4",
        delayAnimation: "100",
      },
      {
        img: "good-neighbor",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "Good Neighbor Community Center",
        meta: "",
        portfolioLink:"9666D6RUOGE",
        delayAnimation: "100",
      },
      {
        img: "moms-sizzle",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "MomsEveryday sizzle reel",
        meta: "",
        portfolioLink:"ibRIY3BTTFU",
        delayAnimation: "100",
      },
      {
        img: "vision-maker",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "\"Vision Maker Media CommUNITY\" spot",
        meta: "",
        portfolioLink:"YGuOoQc5YDk",
        delayAnimation: "100",
      },
      {
        img: "nikki-pledge",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "Nikki pledge anchor examples",
        meta: "",
        portfolioLink:"y_OoggtBK_Y",
        delayAnimation: "0",
      },
      {
        img: "civics",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "Civics 101",
        meta: "",
        portfolioLink:"IdD5kJSvOD4",
        delayAnimation: "100",
      },
      {
        img: "united-way",
        vid: true,
        yt:true,
        channel: "youtube",
        title: "2013/2014 Heartland United Way Campaign Video",
        meta: "",
        portfolioLink:"p56uyluLJBE",
        delayAnimation: "100",
      },
      {
        img: "colleen-new-holy",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "Well Beings:  Colleen New Holy",
        meta: "",
        portfolioLink:"pdHyQwezIoE",
        delayAnimation: "100",
      },
      {
        img: "cat-house",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "Cat House",
        meta: "",
        portfolioLink:"gVDFj57SaRY",
        delayAnimation: "100",
      },
      {
        img: "be-a-mav",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "\"Be a Mav Day\" story",
        meta: "",
        portfolioLink:"a3N1G__3bUU",
        delayAnimation: "100",
      },
      {
        img: "can-caravan",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "Can Care-a-Van promo",
        meta: "",
        portfolioLink: "mnYUDcc-6iM",
        delayAnimation: "0",
      },
      {
        img: "essex",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "Essex Communities",
        meta: "",
        portfolioLink:"tnGUM61dDMU",
        delayAnimation: "100",
      },
      {
        img: "library-storytime",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "Library Storytime",
        meta: "",
        portfolioLink:"ZsqgwU4w5SY",
        delayAnimation: "100",
      },
      {
        img: "nedhal",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "Well Beings: Nedhal al-kazahy",
        meta: "",
        portfolioLink:"oXWtYVIgNPs",
        delayAnimation: "100",
      },
      {
        img: "stroller-strides",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "Stroller Strides",
        meta: "",
        portfolioLink:"yN6HQCMDrmQ",
        delayAnimation: "100",
      },
      {
        img: "bullying-myths",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "Bullying Myths",
        meta: "",
        portfolioLink:"-cZEPMkHBjM",
        delayAnimation: "100",
      },
      {
        img: "carseats",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "Car Seats",
        meta: "",
        portfolioLink:"9Q3dBLuDdZs",
        delayAnimation: "100",
      },
      {
        img: "stay-at-home-dads",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "Stay-at-home Dads",
        meta: "",
        portfolioLink:"NIfAzOQD-08",
        delayAnimation: "100",
      },
      {
        img: "babywearing",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "Babywearing",
        meta: "",
        portfolioLink:"lF-K09B7pQc",
        delayAnimation: "100",
      },
      {
        img: "calm-down",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "Calm Down Kits",
        meta: "",
        portfolioLink: "XTKBK0BItJk",
        delayAnimation: "0",
      },
      {
        img: "moms-on-camera",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "MomsEveryday on-camera examples",
        meta: "",
        portfolioLink: "Fk7eJb487Pg",
        delayAnimation: "0",
      },
      {
        img: "promise-walk",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "Promise Walk Interview",
        meta: "",
        portfolioLink: "fg0NhlfKnNs",
        delayAnimation: "0",
      },
      {
        img: "promise-walk",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "Promise Walk Interview",
        meta: "",
        portfolioLink: "fg0NhlfKnNs",
        delayAnimation: "0",
      },
      {
        img: "moms-full",
        vid: true,
        yt:false,
        channel: "custom",
        title: "MomsEveryday full episode",
        meta: "",
        portfolioLink:"https://d2pvyxdw30n8fd.cloudfront.net/MOMS/v5ac515a78bbd2/114_11-FULL-SHOW.mp4",
        delayAnimation: "100",
      },
      {
        img: "sam-bates",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "Well Beings: Sam Bates",
        meta: "",
        portfolioLink:"KmzphX4lm3Q",
        delayAnimation: "100",
      },
      {
        img: "mar-lee",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "Well Beings: Mar Lee",
        meta: "",
        portfolioLink:"Dx91AMDuNJQ",
        delayAnimation: "100",
      },
      {
        img: "chiropractic",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "Nebraska Chiropractic Physicians Association",
        meta: "",
        portfolioLink:"J_qbTwvfLOs",
        delayAnimation: "100",
      },
      {
        img: "confucius",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "Confucius Institute",
        meta: "",
        portfolioLink:"agd2imH4USk",
        delayAnimation: "100",
      },
    ],
  },
  {
    porftoliItems: [
      {
        img: "nebraska_roots",
        vid: true,
        channel: "custom",
        title: "Nebraska Roots:  Trees + Native Plants",
        meta: "",
        portfolioLink:
          "https://d34oa379y8jhb4.cloudfront.net/NETVOD/1080/50010820.mp4",
        delayAnimation: "0",
      },
      {
        img: "parkinsons",
        vid: true,
        channel: "custom",
        title: "Connects:  Parkinson's Nebraska",
        meta: "",
        portfolioLink:
          "https://d34oa379y8jhb4.cloudfront.net/NETVOD/720/50018188.mp4",
        delayAnimation: "0",
      },
      {
        img: "water_machine",
        vid: true,
        channel: "custom",
        title: "Nebraska:  The Great American Water Machine",
        meta: "",
        portfolioLink:
          "https://d34oa379y8jhb4.cloudfront.net/NETVOD/720/50013668_A.mp4",
        delayAnimation: "100",
      },
      {
        img: "focused",
        vid: true,
        channel: "custom",
        title: "Nebraska Focused:  Building Our Future",
        meta: "",
        portfolioLink:
          "https://d34oa379y8jhb4.cloudfront.net/NETVOD/720/50017302_B.mp4",
        delayAnimation: "100",
      },
      {
        img: "united-way",
        vid: true,
        yt:true,
        channel: "youtube",
        title: "2013/2014 Heartland United Way Campaign Video",
        meta: "",
        portfolioLink:"p56uyluLJBE",
        delayAnimation: "100",
      },
      {
        img: "moms-full",
        vid: true,
        yt:false,
        channel: "custom",
        title: "MomsEveryday full episode",
        meta: "",
        portfolioLink:"https://d2pvyxdw30n8fd.cloudfront.net/MOMS/v5ac515a78bbd2/114_11-FULL-SHOW.mp4",
        delayAnimation: "100",
      },
    ],
  },
  {
    porftoliItems: [
      {
        img: "can-caravan",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "Can Care-a-Van promo",
        meta: "",
        portfolioLink: "mnYUDcc-6iM",
        delayAnimation: "0",
      },
      {
        img: "preclampsia",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "Preeclampsia story",
        meta: "",
        portfolioLink:"MH11OraSTNg",
        delayAnimation: "100",
      },
      {
        img: "be-a-mav",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "\"Be a Mav Day\" story",
        meta: "",
        portfolioLink:"a3N1G__3bUU",
        delayAnimation: "100",
      },
      {
        img: "cat-house",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "Cat House",
        meta: "",
        portfolioLink:"gVDFj57SaRY",
        delayAnimation: "100",
      },
      {
        img: "good-neighbor",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "Good Neighbor Community Center",
        meta: "",
        portfolioLink:"9666D6RUOGE",
        delayAnimation: "100",
      },
      {
        img: "stroller-strides",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "Stroller Strides",
        meta: "",
        portfolioLink:"yN6HQCMDrmQ",
        delayAnimation: "100",
      },
      {
        img: "bullying-myths",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "Bullying Myths",
        meta: "",
        portfolioLink:"-cZEPMkHBjM",
        delayAnimation: "100",
      },
      {
        img: "carseats",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "Car Seats",
        meta: "",
        portfolioLink:"9Q3dBLuDdZs",
        delayAnimation: "100",
      },
      {
        img: "stay-at-home-dads",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "Stay-at-home Dads",
        meta: "",
        portfolioLink:"NIfAzOQD-08",
        delayAnimation: "100",
      },
      {
        img: "library-storytime",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "Library Storytime",
        meta: "",
        portfolioLink:"ZsqgwU4w5SY",
        delayAnimation: "100",
      },
      {
        img: "spring-game",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "Spring Game Coverage promo",
        meta: "",
        portfolioLink:"wQCBqDkGBLU",
        delayAnimation: "100",
      },
      {
        img: "civics",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "Civics 101",
        meta: "",
        portfolioLink:"IdD5kJSvOD4",
        delayAnimation: "100",
      },
      {
        img: "moms-sizzle",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "MomsEveryday sizzle reel",
        meta: "",
        portfolioLink:"ibRIY3BTTFU",
        delayAnimation: "100",
      },
      {
        img: "babywearing",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "Babywearing",
        meta: "",
        portfolioLink:"lF-K09B7pQc",
        delayAnimation: "100",
      },
      {
        img: "vision-maker",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "\"Vision Maker Media CommUNITY\" spot",
        meta: "",
        portfolioLink:"YGuOoQc5YDk",
        delayAnimation: "100",
      },
      {
        img: "nedhal",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "Well Beings: Nedhal al-kazahy",
        meta: "",
        portfolioLink:"oXWtYVIgNPs",
        delayAnimation: "100",
      },
      {
        img: "sam-bates",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "Well Beings: Sam Bates",
        meta: "",
        portfolioLink:"KmzphX4lm3Q",
        delayAnimation: "100",
      },
      {
        img: "mar-lee",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "Well Beings: Mar Lee",
        meta: "",
        portfolioLink:"Dx91AMDuNJQ",
        delayAnimation: "100",
      },
      {
        img: "colleen-new-holy",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "Well Beings:  Colleen New Holy",
        meta: "",
        portfolioLink:"pdHyQwezIoE",
        delayAnimation: "100",
      },
      {
        img: "chiropractic",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "Nebraska Chiropractic Physicians Association",
        meta: "",
        portfolioLink:"J_qbTwvfLOs",
        delayAnimation: "100",
      },
      {
        img: "essex",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "Essex Communities",
        meta: "",
        portfolioLink:"tnGUM61dDMU",
        delayAnimation: "100",
      },
      {
        img: "confucius",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "Confucius Institute",
        meta: "",
        portfolioLink:"agd2imH4USk",
        delayAnimation: "100",
      },
    ],
  },
  {
    porftoliItems: [
      {
        img: "nikki-pledge",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "Nikki pledge anchor examples",
        meta: "",
        portfolioLink:"y_OoggtBK_Y",
        delayAnimation: "0",
      },
      {
        img: "calm-down",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "Calm Down Kits",
        meta: "",
        portfolioLink: "XTKBK0BItJk",
        delayAnimation: "0",
      },
      {
        img: "promise-walk",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "Promise Walk Interview",
        meta: "",
        portfolioLink: "fg0NhlfKnNs",
        delayAnimation: "0",
      },
      {
        img: "moms-on-camera",
        vid: true,
        yt: true,
        channel: "youtube",
        title: "MomsEveryday on-camera examples",
        meta: "",
        portfolioLink: "Fk7eJb487Pg",
        delayAnimation: "0",
      },
    ],
  },
];

const Portfolio = () => {

  const [isOpen, setOpen] = useState(false);
  const [channelValue, setChannelValue] = useState(false);
  const [videoId, setVideoId] = useState(false);
  const [customUrl, setCustomUrl] = useState(false);

  function openVideo(channel, videoId, customUrl){
    console.log("Opening video...");

    setChannelValue(channel);
    if (videoId != null){
      console.log("setting youtube video id to " + videoId);
      setVideoId(videoId);
    }
    else if(customUrl != null){
      console.log("setting custom url to " + customUrl);
      setCustomUrl(customUrl);
    }
    setOpen(true);
  }

  return (
    <div className="shane_tm_section" id="portfolio">
      <ModalVideo
        channel={channelValue}
        autoplay
        videoId = {videoId}
        url = {customUrl}
        isOpen={isOpen}
        onClose={() => setOpen(false)}/>

        <div className="shane_tm_portfolio">
          <div className="container">
            <div className="positon-relative">
              <div className="shane_tm_title">
                <div className="title_flex">
                  <div className="left">
                    <span>Portfolio</span>
                    <h3>Creative Portfolio</h3>
                  </div>
                </div>
              </div>
              {/* End shane_tm_title */}

              <div className="portfolio_filter">
                <Tabs>
                  <TabList>
                    {tabList.map((val, i) => (
                      <Tab key={i}>{val}</Tab>
                    ))}
                  </TabList>
                  {/* End tablist */}

                  <div className="portfolio_list has-effect">
                      {tabListContent.map(function (tabContent, i){
                        return (
                        <TabPanel key={i}>
                          <ul className="gallery_zoom">
                            {tabContent.porftoliItems.map(function(item, i){

                              if(item.vid === true){
                                console.log("Item " + item.title + " has a video");
                                if(item.yt === true){
                                  console.log("Item " + item.title + " is a YouTube video.");
                                  return (
                                    <li
                                      key={i}
                                      data-aos="fade-right"
                                      data-aos-duration="1200"
                                      data-aos-delay={item.delayAnimation}
                                    >
                                      <div className="inner">
                                        <div className="entry shane_tm_portfolio_animation_wrap">
                                          <a onClick={() =>{
                                            openVideo(item.channel, item.portfolioLink, null);
                                          }}>
                                            <img src={`/img/portfolio/${item.img}.jpg`} alt={item.meta}/>
                                          </a>
                                        </div>
                                        <div className="mobile_title">
                                          <h3>
                                            <a onClick={() =>{
                                              openVideo(item.channel, item.portfolioLink, null);
                                            }}>
                                              {item.title}
                                            </a>
                                          </h3>
                                          <span>{item.meta}</span>
                                        </div>
                                      </div>
                                    </li>)
                                }
                                else{
                                  console.log("Item " + item.title + " is a custom video.");
                                  return (
                                    <li
                                      key={i}
                                      data-aos="fade-right"
                                      data-aos-duration="1200"
                                      data-aos-delay={item.delayAnimation}>
                                    <div className="inner">
                                      <div className="entry shane_tm_portfolio_animation_wrap">
                                        <a onClick={() =>{
                                          openVideo(item.channel, null, item.portfolioLink);
                                        }}>
                                          <img src={`/img/portfolio/${item.img}.jpg`} alt={item.meta}/>
                                        </a>
                                      </div>
                                      <div className="mobile_title">
                                        <h3>
                                          <a onClick={() =>{
                                            openVideo(item.channel, null, item.portfolioLink);
                                          }}>
                                            {item.title}
                                          </a>
                                        </h3>
                                        <span>{item.meta}</span>
                                      </div>
                                    </div>
                                    </li>)
                                }
                              }
                              else{
                                return(
                                  <li
                                    key={i}
                                    data-aos="fade-right"
                                    data-aos-duration="1200"
                                    data-aos-delay={item.delayAnimation}
                                  >
                                    <div className="inner">
                                      <div className="entry shane_tm_portfolio_animation_wrap">
                                        <a href={`${item.portfolioLink}`} target="_blank">
                                          <img
                                            src={`/img/portfolio/${item.img}.jpg`}
                                            alt={item.meta}
                                          />
                                        </a>
                                      </div>
                                      <div className="mobile_title">
                                        <h3>
                                          <a
                                            href={item.portfolioLink}
                                            target="_blank"
                                          >
                                            {item.title}
                                          </a>
                                        </h3>
                                        <span>{item.meta}</span>
                                      </div>
                                    </div>
                                  </li>
                              )
                              }
                              })}
                          </ul>
                        </TabPanel>)
                      })}
                    {/* End tabpanel */}
                  </div>
                  {/* End list wrapper */}
                </Tabs>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default Portfolio;
